/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  URL_SEPARATOR,
} from './shared/constants';
import { errorsExtraction } from './shared/utils/error-interceptors';

type Action = {
  type: string;
  e?: {
    response: {
      status: number;
      statusText: string;
      data: {
        model: any;
        message: string;
      };
    };
  };
};

const isRequest = (action: Action): boolean => action.type.slice(-REQUEST.length) === REQUEST;

const isSuccess = (action: Action): boolean => action.type.slice(-SUCCESS.length) === SUCCESS;

const isFailure = (action: Action): boolean => action.type.slice(-FAILURE.length) === FAILURE;

const extractRoot = (type: string): string => {
  const splitType = type.split(URL_SEPARATOR);
  return `app/${splitType[1]}`;
};

const error400And424Handling = (
  store: any,
  next: any,
  action: Action,
): void => {
  if (action.e && isFailure(action)) {
    if (action?.e?.response?.status === 400) {
      const arrayErrors = errorsExtraction(action.e.response.data.model);
      /* Uncomment this line to re-enable notifications for bad request errors */
      // arrayErrors.forEach((error: string) => pushNotification("error", error));
      const objectErrors = action.e.response.data.model;
      next({ ...action, objectErrors, arrayErrors });
      return;
    }
    if (action?.e?.response?.status === 424) {
      const objectErrors = {
        other: [action.e.response.data.message],
      };

      next({
        ...action,
        objectErrors,
        arrayErrors: [action?.e?.response?.data.message],
      });
      return;
    }
  }
  next(action);
};

const error404And415Handling = (
  store: any,
  next: any,
  action: Action,
): void => {
  if (action.e && isFailure(action)) {
    if (action?.e?.response?.status === 404) {
      const objectErrors = {
        other: ['Operation failed'],
      };

      next({ ...action, objectErrors });
      return;
    }
    if (action?.e?.response?.status === 415) {
      const objectErrors = [action?.e?.response?.statusText];

      // objectErrors.forEach((error: string) => pushNotification("error", error));
      next({
        ...action,
        objectErrors,
      });
      return;
    }
  }
  next(action);
};

const error500OrMoreHandling = (
  store: any,
  next: any,
  action: Action,
): void => {
  if (action.e && isFailure(action)) {
    if (action?.e?.response?.status === 500) {
      const arrayErrors = errorsExtraction(action?.e?.response?.data.model);
      // arrayErrors.forEach((error: string) => pushNotification("error", error));
      next({ ...action, arrayErrors, unreachableService: true });
      return;
    }
  }
  next(action);
};

const error403and401Handling = (
  store: any,
  next: any,
  action: Action,
): void => {
  if (action.e && isFailure(action)) {
    if (
      action.e.response
      && action?.e?.response?.status
      && action?.e?.response?.status === 403
    ) {
      const arrayErrors = [action.e.response.statusText];
      // arrayErrors.forEach((error: string) => pushNotification("error", error));

      next({ ...action, arrayErrors });
      return;
    }
    if (
      action?.e?.response
      && action?.e?.response?.status
      && action?.e?.response?.status === 401
    ) {
      const arrayErrors = [action.e.response.statusText];
      // arrayErrors.forEach((error: string) => pushNotification("error", error));
      next({ ...action, arrayErrors });
      return;
    }
  }
  next(action);
};

const clearErrors = (store: any) => (next: any) => (action: Action) => {
  if (isRequest(action) || isSuccess(action)) {
    store.dispatch({
      type: `${extractRoot(action.type)}/CLEAR_ERRORS`,
    });
  }
  next(action);
};

const errorsHandling = (store: any) => (next: any) => (action: Action) => {
  if (action.e && action?.e?.response && isFailure(action)) {
    // eslint-disable-next-line default-case
    switch (action?.e?.response?.status) {
      case 424:
      case 400:
        error400And424Handling(store, next, action);
        break;
      case 403:
        error403and401Handling(store, next, action);
        break;
      case 401:
        error403and401Handling(store, next, action);
        break;
      case 404:
        error404And415Handling(store, next, action);
        break;
      case 415:
        error404And415Handling(store, next, action);
        break;
    }
    if (
      action?.e?.response
      && action?.e?.response?.status
      && action?.e?.response?.status >= 500
    ) {
      error500OrMoreHandling(store, next, action);
    }
  } else if (isFailure(action)) {
    error500OrMoreHandling(store, next, action);
  } else {
    next(action);
  }
};

export default [errorsHandling, clearErrors];
