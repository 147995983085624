import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const generateCheckpointFeedback = async (values: {
  query: [];
  checkpointAssignmentId: string;
}) => {
  const { query, checkpointAssignmentId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.IA.generateCheckpointFeedback(checkpointAssignmentId) + queryString,
  );
  return result.data;
};

export const regenerateCheckpointFeedback = async (values: {
  query: [];
  checkpointAssignmentId: string;
  body:{}
}) => {
  const { checkpointAssignmentId, body } = values;
  const result = await axiosInstance.put(URL.IA.regenerateCheckpointFeedback(checkpointAssignmentId), { ...body });
  return result.data;
};

export default {
  generateCheckpointFeedback,
  regenerateCheckpointFeedback,
};
