import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import * as aiApi from '../../shared/services/ai/checkpoints';
import * as phaseAiApi from '../../shared/services/ai/phase';
import constants from './constants';

export function* generateCheckpointFeedback(action: {
    type: string;
    values: {
      query: [];
      checkpointAssignmentId: string;
    };
  }) {
  try {
    const result : ReturnType<typeof aiApi.generateCheckpointFeedback> = yield call(
      aiApi.generateCheckpointFeedback,
      action.values,
    );
    yield put({
      type: constants.generateCheckpointFeedback.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.generateCheckpointFeedback.failure,
      e,
    });
  }
}

export function* generateCheckpointFeedbackWatcher() {
  yield takeEvery(
    constants.generateCheckpointFeedback.request,
    generateCheckpointFeedback,
  );
}

export function* resetGenerateCheckpointFeedbackFailure(action: { type: string; body: [] }) {
  try {
    yield put({
      type: constants.resetGenerateCheckpointFeedbackFailure.success,
      request: action.body,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.resetGenerateCheckpointFeedbackFailure.failure,
      e,
    });
  }
}

export function* resetGenerateCheckpointFeedbackFailureWatcher() {
  yield takeEvery(constants.resetGenerateCheckpointFeedbackFailure.request, resetGenerateCheckpointFeedbackFailure);
}

export function* generatePhaseFeedback(action: {
  type: string;
  values: {
    query: [];
    phaseAssignementId: string;
  };
}) {
  try {
    const result : ReturnType<typeof phaseAiApi.generatePhaseFeedback> = yield call(
      phaseAiApi.generatePhaseFeedback,
      action.values,
    );
    yield put({
      type: constants.generatePhaseFeedback.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.generatePhaseFeedback.failure,
      e,
    });
  }
}

export function* generatePhaseFeedbackWatcher() {
  yield takeEvery(
    constants.generatePhaseFeedback.request,
    generatePhaseFeedback,
  );
}

export function* setResetPhaseNotification(action: { type: string; body: boolean }) {
  try {
    yield put({
      type: constants.setResetPhaseNotification.success,
      request: action.body,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setResetPhaseNotification.failure,
      e,
    });
  }
}

export function* setResetPhaseNotificationWatcher() {
  yield takeEvery(constants.setResetPhaseNotification.request, setResetPhaseNotification);
}

export function* setResetopenValidation(action: { type: string; body: boolean }) {
  try {
    yield put({
      type: constants.setResetopenValidation.success,
      request: action.body,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setResetopenValidation.failure,
      e,
    });
  }
}

export function* setResetopenValidationWatcher() {
  yield takeEvery(constants.setResetopenValidation.request, setResetopenValidation);
}

export function* setModalOpen(action: { type: string; body: boolean }) {
  try {
    yield put({
      type: constants.setModalOpen.success,
      request: action.body,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setModalOpen.failure,
      e,
    });
  }
}

export function* setModalOpenWatcher() {
  yield takeEvery(constants.setModalOpen.request, setModalOpen);
}
export function* regenerateCheckpointFeedback(action: {
  type: string;
  values: {
    query: [];
    checkpointAssignmentId: string;
    body:{}
  };
}) {
  try {
    const result : ReturnType<typeof aiApi.regenerateCheckpointFeedback> = yield call(
      aiApi.regenerateCheckpointFeedback,
      action.values,
    );
    yield put({
      type: constants.regenerateCheckpointFeedback.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.regenerateCheckpointFeedback.failure,
      e,
    });
  }
}

export function* regenerateCheckpointFeedbackWatcher() {
  yield takeEvery(
    constants.regenerateCheckpointFeedback.request,
    regenerateCheckpointFeedback,
  );
}

export default function* aiSaga() {
  yield all([
    generateCheckpointFeedbackWatcher(),
    resetGenerateCheckpointFeedbackFailureWatcher(),
    generatePhaseFeedbackWatcher(),
    setResetPhaseNotificationWatcher(),
    setResetopenValidationWatcher(),
    setModalOpenWatcher(),
    regenerateCheckpointFeedbackWatcher(),
  ]);
}
