import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/AI/';
export default {
  generateCheckpointFeedback: generateActionTypes(root, 'GENERATE_CHECKPOINT_FEEDBACK'),
  resetGenerateCheckpointFeedbackFailure: generateActionTypes(root, 'RESET_GENERATE_CHECKPOINT_FEEDBACK_FAILURE'),
  generatePhaseFeedback: generateActionTypes(root, 'GENERATE_PHASE_FEEDBACK'),
  setResetPhaseNotification: generateActionTypes(root, 'SET_RESET_PHASE_NOTIFICATION'),
  setResetopenValidation: generateActionTypes(root, 'SET_RESET_OPEN_VALIDATION'),
  setModalOpen: generateActionTypes(root, 'SET_MODAL_OPEN'),
  regenerateCheckpointFeedback: generateActionTypes(root, 'REGENERATE_CHECKPOINT_FEEDBACK'),
};
