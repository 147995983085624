import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const generatePhaseFeedback = async (values: {
  query: [];
  phaseAssignementId: string;
}) => {
  const { query, phaseAssignementId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.put(
    URL.IA.generatePhaseFeedback(phaseAssignementId),
    null + queryString,
  );
  return result.data;
};
export default {
  generatePhaseFeedback,
};
