import { Reducer } from 'redux';
import constants from './constants';
import { AIState } from './types';

export const initialState: AIState = {
  generateCheckpointFeedbackLoading: false,
  generateCheckpointFeedbackErrors: [],
  generatePhaseFeedbackErrors: [],
  generatePhaseFeedbackLoading: false,
  openFailedPhaseErrorNotification: false,
  shouldRefetchCheckpoint: false,
  openValidateView: false,
  openCheckpointFailureModal: false,
  checkpointLLMErrors: [],
  regenerateCheckpointFeedbackLoading: false,
  regenerateCheckpointFeedbackErrors: [],
  openWaitingNotification: false,
  openFailedNotification: false,
  apiTriggeredLoading: {},
};

const reducer: Reducer<AIState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action,
) => {
  switch (action.type) {
    case constants.generateCheckpointFeedback.request:
      return {
        ...state,
        generateCheckpointFeedbackLoading: true,
        generateCheckpointFeedbackErrors: [],
        openValidateView: false,
        openCheckpointFailureModal: false,
        shouldRefetchCheckpoint: false,
        openWaitingNotification: true,
        apiTriggeredLoading: {
          ...state.apiTriggeredLoading,
          [action?.values?.checkpointAssignmentId]: true, // Track per checkpoint
        },
      };

    case constants.generateCheckpointFeedback.success:

      return {
        ...state,
        generateCheckpointFeedbackLoading: false,
        shouldRefetchCheckpoint: true,
        generateCheckpointFeedbackErrors: [],
        openValidateView: true,
        openCheckpointFailureModal: false,
        openWaitingNotification: false,
        apiTriggeredLoading: {
          ...state.apiTriggeredLoading,
          [action?.values?.checkpointAssignmentId]: false, // Track per checkpoint
        },

      };
    case constants.generateCheckpointFeedback.failure:
      return {
        ...state,
        generateCheckpointFeedbackLoading: false,
        shouldRefetchCheckpoint: true,
        generateCheckpointFeedbackErrors: action.arrayErrors,
        checkpointLLMErrors: action?.e?.llM_ERROR,
        openValidateView: false,
        openCheckpointFailureModal: true,
        openWaitingNotification: false,
        apiTriggeredLoading: false,
      };
    case constants.regenerateCheckpointFeedback.request:
      return {
        ...state,
        regenerateCheckpointFeedbackLoading: true,
        regenerateCheckpointFeedbackErrors: [],
        shouldRefetchCheckpoint: false,
        openWaitingNotification: true,
        openFailedNotification: false,
      };

    case constants.regenerateCheckpointFeedback.success:
      return {
        ...state,
        regenerateCheckpointFeedbackLoading: false,
        shouldRefetchCheckpoint: true,
        regenerateCheckpointFeedbackErrors: [],
        openWaitingNotification: false,

      };
    case constants.regenerateCheckpointFeedback.failure:
      return {
        ...state,
        regenerateCheckpointFeedbackLoading: false,
        openWaitingNotification: false,
        openFailedNotification: true,
        regenerateCheckpointFeedbackErrors: action.arrayErrors,
      };
    case constants.setResetopenValidation.success:
      return {
        ...state,
        openValidateView: action.data,
      };
    case constants.resetGenerateCheckpointFeedbackFailure.success:
      return {
        ...state,
        checkpointLLMErrors: action.data,
        openCheckpointFailureModal: false,

      };

    case constants.setModalOpen.success:
      return {
        ...state,
        openCheckpointFailureModal: action.data,
      };

    case constants.generatePhaseFeedback.request:
      return {
        ...state,
        generatePhaseFeedbackLoading: true,
      };

    case constants.generatePhaseFeedback.success:
      return {
        ...state,
        generatePhaseFeedbackLoading: false,
      };

    case constants.generatePhaseFeedback.failure: {
      return {
        ...state,
        generatePhaseFeedbackLoading: false,
        generatePhaseFeedbackErrors: action.arrayErrors || [],
        openFailedPhaseErrorNotification: true,
      };
    }

    case constants.setResetPhaseNotification.success: {
      return {
        ...state,
        openFailedPhaseErrorNotification: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as aiReducer };
